"use client";

import React from "react";
import NextImage, { StaticImageData } from "next/image";
import Link from "next/link";
import { Route } from "@/routers/types";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { useState, useCallback, useEffect } from "react";

export interface GallerySliderProps {
  className?: string;
  galleryImgs: (StaticImageData | string)[];
  ratioClass?: string;
  uniqueID: string;
  href?: string;
  imageClass?: string;
  galleryClass?: string;
  navigation?: boolean;
}

export default function GallerySlider({
  className = "",
  galleryImgs,
  ratioClass = "aspect-w-4 aspect-h-3",
  imageClass = "",
  uniqueID = "uniqueID",
  galleryClass = "rounded-xl",
  href = "/listing-product-detail/:id",
  navigation = true,
}: GallerySliderProps) {
  const [loaded, setLoaded] = useState(false);
  const [imagesLoaded, setImagesLoaded] = useState<boolean[]>([]);
  const [swiper, setSwiper] = useState<any>(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const images = galleryImgs;

  const preloadImages = useCallback(() => {
    images.forEach((src, index) => {
      const imgSrc = typeof src === 'string' ? src : src.src;
      const img = new window.Image();
      img.src = imgSrc;
      img.onload = () => {
        setImagesLoaded(prev => {
          const newLoaded = [...prev];
          newLoaded[index] = true;
          return newLoaded;
        });
      };
    });
  }, [images]);

  useEffect(() => {
    preloadImages();
  }, [preloadImages]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'ArrowLeft' && currentIndex > 0) {
        swiper?.slidePrev();
      } else if (e.key === 'ArrowRight' && currentIndex < images.length - 1) {
        swiper?.slideNext();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [currentIndex, images.length, swiper]);

  return (
    <div
      className={`relative group group/cardGallerySlider ${className}`}
      id={uniqueID}
    >
      {/* Buttons + bottom nav bar */}
      {loaded && (
        <>
          {/* Navigation buttons - Moved above the gallery */}
          {navigation && (
            <>
              {/* Side Navigation Buttons - Hidden on mobile, visible on hover for desktop */}
              <div className="opacity-0 group-hover/cardGallerySlider:opacity-100 transition-opacity duration-300 absolute inset-0 z-[5] pointer-events-none">
                {currentIndex > 0 && (
                  <button
                    className="absolute w-8 h-8 left-3 top-[calc(50%-16px)] bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-6000 dark:hover:border-neutral-500 rounded-full flex items-center justify-center hover:border-neutral-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 pointer-events-auto"
                    style={{ transform: "translate3d(0, 0, 0)" }}
                    onClick={(e) => {
                      e.preventDefault();
                      swiper?.slidePrev();
                    }}
                    aria-label="Previous image"
                  >
                    <ChevronLeftIcon className="h-4 w-4" />
                  </button>
                )}
                {currentIndex < images.length - 1 && (
                  <button
                    className="absolute w-8 h-8 right-3 top-[calc(50%-16px)] bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-6000 dark:hover:border-neutral-500 rounded-full flex items-center justify-center hover:border-neutral-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 pointer-events-auto"
                    style={{ transform: "translate3d(0, 0, 0)" }}
                    onClick={(e) => {
                      e.preventDefault();
                      swiper?.slideNext();
                    }}
                    aria-label="Next image"
                  >
                    <ChevronRightIcon className="h-4 w-4" />
                  </button>
                )}
              </div>

              {/* Bottom Nav bar - Always visible */}
              <div className="absolute bottom-0 inset-x-0 z-[5]">
                <div className="absolute bottom-0 inset-x-0 h-10 bg-gradient-to-t from-neutral-900 opacity-50 rounded-b-lg pointer-events-none"></div>
                <div className="flex items-center justify-center absolute bottom-2 left-1/2 transform -translate-x-1/2 space-x-1.5 pointer-events-auto">
                  {images.map((_, i) => (
                    <button
                      className={`w-1.5 h-1.5 rounded-full transition-all duration-200 ${
                        i === currentIndex ? "bg-white w-2.5" : "bg-white/60 hover:bg-white/80"
                      }`}
                      onClick={(e) => {
                        e.preventDefault();
                        swiper?.slideTo(i);
                      }}
                      key={i}
                      aria-label={`Go to image ${i + 1}`}
                    />
                  ))}
                </div>
              </div>
            </>
          )}
        </>
      )}

      <div className={`w-full overflow-hidden ${galleryClass}`}>
        <Link
          href={href as Route}
          className={`relative flex items-center justify-center ${ratioClass}`}
          aria-label={`View details for image ${currentIndex + 1} of ${images.length}`}
        >
          <Swiper
            loop={true}
            className="!static !w-full !h-full"
            onSwiper={setSwiper}
            onSlideChange={(swiper) => setCurrentIndex(swiper.realIndex)}
            onInit={() => setLoaded(true)}
          >
            {images.map((img, index) => (
              <SwiperSlide key={index} className="!w-full !h-full relative">
                <NextImage
                  src={img}
                  alt={`Gallery image ${index + 1}`}
                  className={`object-cover ${imageClass}`}
                  fill
                  sizes="(max-width: 480px) 100vw, (max-width: 768px) 50vw, 33vw"
                  priority={index === 0}
                  quality={90}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </Link>
      </div>

      {/* Loading indicator */}
      {!imagesLoaded[currentIndex] && (
        <div className="absolute inset-0 flex items-center justify-center bg-neutral-200 dark:bg-neutral-800 z-[6]">
          <div className="w-10 h-10 border-4 border-primary-500 border-t-transparent rounded-full animate-spin"></div>
        </div>
      )}

      <style jsx global>{`
        #${uniqueID} .swiper {
          position: static !important;
          overflow: hidden;
          width: 100% !important;
          height: 100% !important;
        }
        #${uniqueID} .swiper-wrapper {
          position: absolute !important;
          top: 0 !important;
          left: 0 !important;
          right: 0 !important;
          bottom: 0 !important;
          display: flex;
          height: 100% !important;
        }
        #${uniqueID} .swiper-slide {
          position: relative !important;
          flex-shrink: 0;
          width: 100% !important;
          height: 100% !important;
        }
      `}</style>
    </div>
  );
}