"use client";

import React, { FC, useState, useEffect, useContext } from "react";
import { AuthContext } from "@/context/AuthProvider";

export interface BtnLikeIconProps {
  className?: string;
  colorClass?: string;
  isLiked?: boolean;
  itemId?: string | number; // Updated to accept both string and number
}

const BtnLikeIcon: FC<BtnLikeIconProps> = ({
  className = "",
  colorClass = "text-white bg-black bg-opacity-30 hover:bg-opacity-50",
  isLiked = false,
  itemId,
}) => {
  const [likedState, setLikedState] = useState(isLiked);
  const authContext = useContext(AuthContext);
  const userEmail = authContext?.user?.email || "guest";

  // Convert itemId to string to ensure consistent storage and comparison
  const itemIdString = itemId?.toString();

  useEffect(() => {
    const wishlist = JSON.parse(localStorage.getItem(`wishlist_${userEmail}`) || "[]");
    setLikedState(wishlist.includes(itemIdString));
  }, [userEmail, itemIdString]);

  const toggleLike = () => {
    const newLikedState = !likedState;
    setLikedState(newLikedState);

    let wishlist = JSON.parse(localStorage.getItem(`wishlist_${userEmail}`) || "[]");
    
    if (newLikedState) {
      wishlist.push(itemIdString);
    } else {
      wishlist = wishlist.filter((id: string) => id !== itemIdString);
    }

    localStorage.setItem(`wishlist_${userEmail}`, JSON.stringify(wishlist));
  };

  return (
    <div
      className={`nc-BtnLikeIcon w-8 h-8 flex items-center justify-center rounded-full cursor-pointer ${
        likedState ? "nc-BtnLikeIcon--liked" : ""
      }  ${colorClass} ${className}`}
      data-nc-id="BtnLikeIcon"
      title="Favoris"
      onClick={toggleLike}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-5 w-5"
        fill={likedState ? "currentColor" : "none"}
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
        />
      </svg>
    </div>
  );
};

export default BtnLikeIcon;